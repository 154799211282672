import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { CreditCard, Shield, CheckCircle, Mail, Clock, AlertCircle } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { prescriptionService } from '../../../services/prescriptionService';
import { sendSubmissionEmail } from '../../../services/emailService';
import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../lib/api';
import type { Strain, Pharmacy } from '../../../types';

// Initialize Stripe with Vite environment variable
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY || '');

interface PaymentFormProps {
  formData: {
    personalInfo: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      dateOfBirth: string;
      address: string;
    };
    conditions: string[];
    medications: string[];
    notes?: string;
    selectedPharmacy: Pharmacy;
    selectedStrains: { strain: Strain; amount: number }[];
  };
  clientSecret: string;
  onPaymentComplete: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ formData, clientSecret, onPaymentComplete }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [processing, setProcessing] = useState(false);
  const { user } = useAuth();

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    setError(null);

    try {
      // Confirm the payment
      const { error: stripeError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Optionally, you can add a return URL or other params
        },
        redirect: 'if_required',
      });

      if (stripeError) {
        throw new Error(stripeError.message);
      }

      if (paymentIntent?.status === 'succeeded') {
        // Create prescription on the server
        const prescriptionData = {
          patientName: `${formData.personalInfo.firstName} ${formData.personalInfo.lastName}`,
          conditions: formData.conditions,
          medications: formData.medications || [],
          notes: formData.notes || '',
          urgency: 'medium',
          patientInfo: {
            firstName: formData.personalInfo.firstName,
            lastName: formData.personalInfo.lastName,
            email: formData.personalInfo.email,
            phone: formData.personalInfo.phone,
            dateOfBirth: formData.personalInfo.dateOfBirth,
            address: formData.personalInfo.address,
          },
          pharmacy: formData.selectedPharmacy,
          strains: formData.selectedStrains,
          payment_intent_id: paymentIntent.id,
        };

        const response = await prescriptionService.createPrescription(prescriptionData);

        // Send confirmation email
        await sendSubmissionEmail({
          firstName: formData.personalInfo.firstName,
          lastName: formData.personalInfo.lastName,
          email: formData.personalInfo.email,
          prescriptionId: response.id,
        });

        onPaymentComplete();
      }
    } catch (err: any) {
      console.error('Payment error:', err);
      setError(err.message || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
    } finally {
      setProcessing(false);
    }
  };

  if (!stripe || !elements) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="w-8 h-8 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <PaymentElement />

      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center gap-2 p-4 bg-red-50 text-red-700 rounded-lg"
        >
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p>{error}</p>
        </motion.div>
      )}

      <button
        type="submit"
        disabled={processing || !stripe || !elements}
        className="w-full py-3 bg-gradient-to-r from-green-600 to-emerald-600 text-white rounded-xl hover:from-green-700 hover:to-emerald-700 transition-all duration-300 flex items-center justify-center gap-2 disabled:opacity-50"
      >
        {processing ? (
          <>
            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
            Wird verarbeitet...
          </>
        ) : (
          <>
            <CreditCard className="w-5 h-5" />
            0,60€ bezahlen
          </>
        )}
      </button>

      <div className="flex items-center justify-center gap-2 text-sm text-gray-500">
        <Shield className="w-4 h-4" />
        <span>Sichere Bezahlung mit Stripe</span>
      </div>
    </form>
  );
};

interface MainPaymentProps {
  formData: {
    personalInfo: {
      firstName: string;
      lastName: string;
      email: string;
      phone: string;
      dateOfBirth: string;
      address: string;
    };
    conditions: string[];
    medications: string[];
    notes?: string;
    selectedPharmacy: Pharmacy;
    selectedStrains: { strain: Strain; amount: number }[];
  };
  onPaymentComplete: () => void;
}

const Payment: React.FC<MainPaymentProps> = ({ formData, onPaymentComplete }) => {
  const [succeeded, setSucceeded] = useState(false);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initializePayment = async () => {
      try {
        // Create a payment intent on the server
        const response = await api.post('/api/create-payment-intent', {
          amount: 60, // 0.60€ in cents
          currency: 'eur',
        });
        setClientSecret(response.data.clientSecret);
      } catch (err: any) {
        setError('Fehler beim Initialisieren der Zahlung');
        console.error('Payment initialization error:', err);
      } finally {
        setLoading(false);
      }
    };

    initializePayment();
  }, []);

  if (succeeded) {
    return (
      <motion.div
        key="success"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className="bg-white rounded-xl p-8 shadow-sm text-center"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 200, delay: 0.2 }}
          className="w-20 h-20 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-6"
        >
          <CheckCircle className="w-10 h-10 text-green-600" />
        </motion.div>

        <h3 className="text-2xl font-bold text-gray-900 mb-4">
          Rezeptanfrage erfolgreich übermittelt!
        </h3>

        <div className="space-y-6 text-gray-600">
          <p>
            Deine Rezeptanfrage wurde erfolgreich an einen unserer qualifizierten Ärzte weitergeleitet.
          </p>

          <div className="bg-green-50 p-4 rounded-xl space-y-4">
            <div className="flex items-center gap-3 justify-center">
              <Mail className="w-5 h-5 text-green-600" />
              <p className="text-green-700">
                Eine Bestätigungs-E-Mail wurde an {formData.personalInfo.email} gesendet
              </p>
            </div>
            <div className="flex items-center gap-3 justify-center">
              <Clock className="w-5 h-5 text-green-600" />
              <p className="text-green-700">
                Durchschnittliche Bearbeitungszeit: 24-48 Stunden
              </p>
            </div>
          </div>

          <p className="text-sm">
            Unser Ärzteteam wird deine Anfrage sorgfältig prüfen. Bei Rückfragen oder zusätzlich benötigten
            Informationen kontaktieren wir dich umgehend per E-Mail.
          </p>
        </div>
      </motion.div>
    );
  }

  if (loading) {
    return (
      <div className="flex justify-center items-center p-8">
        <div className="w-8 h-8 border-4 border-green-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center gap-2 p-4 bg-red-50 text-red-700 rounded-lg">
        <AlertCircle className="w-5 h-5 flex-shrink-0" />
        <p>{error}</p>
      </div>
    );
  }

  if (!clientSecret) {
    return (
      <div className="flex items-center gap-2 p-4 bg-red-50 text-red-700 rounded-lg">
        <AlertCircle className="w-5 h-5 flex-shrink-0" />
        <p>Client Secret nicht verfügbar</p>
      </div>
    );
  }

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe',
      variables: {
        colorPrimary: '#16a34a',
      },
    },
  };

  return (
    <div className="space-y-8">
      <Elements stripe={stripePromise} options={options}>
        <PaymentForm
          formData={formData}
          clientSecret={clientSecret}
          onPaymentComplete={() => {
            setSucceeded(true);
            setTimeout(onPaymentComplete, 3000);
          }}
        />
      </Elements>
    </div>
  );
};

export default Payment;
