import emailjs from '@emailjs/browser';

// Initialize EmailJS with public key
emailjs.init(import.meta.env.VITE_EMAILJS_PUBLIC_KEY);

// Prescription submission confirmation
export const sendSubmissionEmail = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  prescriptionId: string;
}) => {
  try {
    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID_PRESCRIPTION_SUBMISSION,
      {
        to_name: `${data.firstName} ${data.lastName}`,
        to_email: data.email,
        prescription_id: data.prescriptionId,
        submission_date: new Date().toLocaleDateString('de-DE'),
      }
    );
    return true;
  } catch (error) {
    console.error('Error sending submission email:', error);
    throw error;
  }
};

// Prescription processing notification
export const sendProcessingEmail = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  prescriptionId: string;
  doctorName: string;
}) => {
  try {
    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID_PRESCRIPTION_PROCESSING,
      {
        to_name: `${data.firstName} ${data.lastName}`,
        to_email: data.email,
        prescription_id: data.prescriptionId,
        doctor_name: data.doctorName,
        processing_date: new Date().toLocaleDateString('de-DE'),
      }
    );
    return true;
  } catch (error) {
    console.error('Error sending processing email:', error);
    throw error;
  }
};

// Prescription approval notification
export const sendApprovalEmail = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  prescriptionId: string;
  doctorName: string;
}) => {
  try {
    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID_PRESCRIPTION_APPROVED,
      {
        to_name: `${data.firstName} ${data.lastName}`,
        to_email: data.email,
        prescription_id: data.prescriptionId,
        doctor_name: data.doctorName,
        approval_date: new Date().toLocaleDateString('de-DE'),
      }
    );
    return true;
  } catch (error) {
    console.error('Error sending approval email:', error);
    throw error;
  }
};

// Prescription rejection notification
export const sendRejectionEmail = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  prescriptionId: string;
  doctorName: string;
  rejectionReason: string;
}) => {
  try {
    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID_PRESCRIPTION_REJECTED,
      {
        to_name: `${data.firstName} ${data.lastName}`,
        to_email: data.email,
        prescription_id: data.prescriptionId,
        doctor_name: data.doctorName,
        rejection_reason: data.rejectionReason,
        rejection_date: new Date().toLocaleDateString('de-DE'),
      }
    );
    return true;
  } catch (error) {
    console.error('Error sending rejection email:', error);
    throw error;
  }
};

// Partner request emails
export const sendPartnerRequest = async (data: {
  pharmacyName: string;
  contactPerson: string;
  email: string;
  phone: string;
  address: string;
  message: string;
}) => {
  try {
    // First email: Send notification to Canniva (info@canniva.de)
    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID_PARTNER_REQUEST,
      {
        pharmacy_name: data.pharmacyName,
        contact_person: data.contactPerson,
        contact_email: data.email,
        contact_phone: data.phone,
        address: data.address,
        message: data.message || 'Keine Nachricht angegeben',
        to_name: 'Canniva Team',
        to_email: 'info@canniva.de',
        reply_to: data.email
      }
    );

    // Second email: Send confirmation to pharmacy
    await emailjs.send(
      import.meta.env.VITE_EMAILJS_SERVICE_ID,
      import.meta.env.VITE_EMAILJS_TEMPLATE_ID_PARTNER_CONFIRMATION,
      {
        to_name: data.contactPerson,
        to_email: data.email,
        pharmacy_name: data.pharmacyName,
        contact_person: data.contactPerson
      }
    );

    return true;
  } catch (error) {
    console.error('Error sending partner request emails:', error);
    throw error;
  }
};