import { io, Socket } from 'socket.io-client';
import { Notification } from '../types/notifications';

class WebSocketClient {
  private static instance: WebSocketClient;
  private socket: Socket | null = null;
  private reconnectAttempts = 0;
  private readonly maxReconnectAttempts = 5;

  private constructor() {}

  static getInstance(): WebSocketClient {
    if (!WebSocketClient.instance) {
      WebSocketClient.instance = new WebSocketClient();
    }
    return WebSocketClient.instance;
  }

  initialize(token: string): Socket {
    if (!this.socket) {
      const socketUrl = import.meta.env.VITE_API_URL;
      
      this.socket = io(socketUrl, {
        auth: { token },
        transports: ['websocket', 'polling'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: this.maxReconnectAttempts,
        secure: true,
        rejectUnauthorized: false
      });

      this.setupEventListeners();
    }

    return this.socket;
  }

  private setupEventListeners(): void {
    if (!this.socket) return;

    this.socket.on('connect', () => {
      console.log('WebSocket connected');
      this.reconnectAttempts = 0;
    });

    this.socket.on('connect_error', (error) => {
      console.error('WebSocket connection error:', error);
      this.reconnectAttempts++;

      if (this.reconnectAttempts >= this.maxReconnectAttempts) {
        this.close();
        console.error('Max reconnection attempts reached');
      }

      if (this.socket?.io.opts?.transports?.[0] === 'websocket') {
        this.socket.io.opts.transports = ['polling', 'websocket'];
      }
    });

    this.socket.on('disconnect', () => {
      console.log('WebSocket disconnected');
    });

    this.socket.on('error', (error) => {
      console.error('WebSocket error:', error);
    });

    // Add prescription notification handler
    this.socket.on('prescription:new', (data) => {
      const notification: Notification = {
        id: Date.now().toString(),
        type: 'prescription',
        title: 'Neue Rezeptanfrage',
        message: `Neue Rezeptanfrage von ${data.patientName}`,
        timestamp: new Date().toISOString(),
        read: false,
        data: {
          prescriptionId: data.id,
          patientName: data.patientName,
          status: 'pending'
        }
      };

      this.dispatchNotification(notification);
    });
  }

  private dispatchNotification(notification: Notification): void {
    window.dispatchEvent(
      new CustomEvent('notification', { detail: notification })
    );
  }

  on(event: string, callback: (data: any) => void): void {
    this.socket?.on(event, callback);
  }

  off(event: string, callback?: (data: any) => void): void {
    this.socket?.off(event, callback);
  }

  emit(event: string, data: any): void {
    this.socket?.emit(event, data);
  }

  close(): void {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }

  getSocket(): Socket | null {
    return this.socket;
  }
}

export const webSocketClient = WebSocketClient.getInstance();

export const initializeWebSocket = (token: string): Socket => {
  return webSocketClient.initialize(token);
};

export const closeWebSocket = (): void => {
  webSocketClient.close();
};

export const getSocket = (): Socket | null => {
  return webSocketClient.getSocket();
};