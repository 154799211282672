import api from '../lib/api';
import type { Prescription, DashboardStats } from '../types/prescription';

export const prescriptionService = {
  async createPrescription(prescriptionData: any): Promise<Prescription> {
    try {
      const response = await api.post('/prescriptions/create', prescriptionData);
      return response.data.prescription;
    } catch (error) {
      console.error('Error creating prescription:', error);
      throw error;
    }
  },

  async fetchPrescriptions(): Promise<Prescription[]> {
    try {
      const response = await api.get('/api/prescriptions');
      if (!response.data || !response.data.prescriptions) {
        throw new Error('Invalid response format');
      }
      return response.data.prescriptions;
    } catch (error) {
      console.error('Error fetching prescriptions:', error);
      throw error;
    }
  },

  async assignPrescription(id: string): Promise<void> {
    try {
      await api.post(`/api/prescriptions/${id}/assign`);
    } catch (error) {
      console.error('Error assigning prescription:', error);
      throw error;
    }
  },

  async updatePrescriptionStatus(id: string, status: string): Promise<void> {
    try {
      await api.put(`/api/prescriptions/${id}`, { status });
    } catch (error) {
      console.error('Error updating prescription status:', error);
      throw error;
    }
  },

  calculateStats(prescriptions: Prescription[]): DashboardStats {
    const pendingPrescriptions = prescriptions.filter(p => p.status === 'pending').length;
    const completedPrescriptions = prescriptions.filter(p => p.status === 'completed').length;
    const totalEarnings = completedPrescriptions * 5.16; // 5.16€ per prescription
    const availableBalance = totalEarnings;
    const monthlyGrowth = prescriptions.length > 0 ? 
      (completedPrescriptions / prescriptions.length) * 100 : 0;
    const uniquePatients = new Set(prescriptions.map(p => p.patient_id)).size;

    return {
      pendingPrescriptions,
      completedPrescriptions,
      totalEarnings,
      availableBalance,
      monthlyGrowth,
      activePatients: uniquePatients,
      lastMonthStats: {
        prescriptions: Math.floor(completedPrescriptions * 0.8),
        earnings: Math.floor(totalEarnings * 0.8),
        patients: Math.floor(uniquePatients * 0.8)
      }
    };
  }
};

export default prescriptionService;