import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import PersonalInfo from './steps/PersonalInfo';
import Consent from './steps/Consent';
import MedicalHistory from './steps/MedicalHistory';
import Medications from './steps/Medications';
import ExclusionCriteria from './steps/ExclusionCriteria';
import RiskDisclosure from './steps/RiskDisclosure';
import IDVerification from './steps/IDVerification';
import PharmacySelection from './steps/PharmacySelection';
import StrainSelection from './steps/StrainSelection';
import Payment from './steps/Payment';
import { useAuth } from '../../contexts/AuthContext';
import type { Pharmacy, Strain } from '../../types';

interface PrescriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const steps = [
  'Persönliche Angaben',
  'Einwilligungen',
  'Medizinische Vorgeschichte',
  'Medikamente',
  'Ausschlusskriterien',
  'Informationspflicht',
  'Altersverifikation',
  'Apotheke',
  'Cannabis-Sorten',
  'Bezahlung',
];

const PrescriptionModal: React.FC<PrescriptionModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { user } = useAuth();
  const [currentStep, setCurrentStep] = useState(0);
  const [processTerminated, setProcessTerminated] = useState(false);
  const [formData, setFormData] = useState({
    personalInfo: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      dateOfBirth: '',
      address: user?.address || '',
      phone: user?.phone || '',
      email: user?.email || '',
    },
    consents: [] as string[],
    conditions: [] as string[],
    hasMedications: null as boolean | null,
    medications: [] as string[],
    exclusionCriteria: [] as string[],
    understoodRisks: false,
    idDocument: null as File | null,
    idVerified: false,
    selectedPharmacy: null as Pharmacy | null,
    selectedStrains: [] as { strain: Strain; amount: number }[],
    paymentComplete: false,
    notes: '',
  });

  const updateFormData = (section: string, data: any) => {
    setFormData((prev) => ({
      ...prev,
      [section]: data,
    }));

    if (section === 'exclusionCriteria' && data.length > 0) {
      setProcessTerminated(true);
    }
  };

  const handleNext = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0 && !processTerminated) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleComplete = () => {
    onClose();
    // Reset form data
    setFormData({
      personalInfo: {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        dateOfBirth: '',
        address: user?.address || '',
        phone: user?.phone || '',
        email: user?.email || '',
      },
      consents: [],
      conditions: [],
      hasMedications: null,
      medications: [],
      exclusionCriteria: [],
      understoodRisks: false,
      idDocument: null,
      idVerified: false,
      selectedPharmacy: null,
      selectedStrains: [],
      paymentComplete: false,
      notes: '',
    });
    setCurrentStep(0);
    setProcessTerminated(false);
  };

  const canProceed = () => {
    if (processTerminated) return false;
    switch (currentStep) {
      case 0: // Personal Info
        const { firstName, lastName, dateOfBirth, address, phone, email } =
          formData.personalInfo;
        return firstName && lastName && dateOfBirth && address && phone && email;
      case 1: // Consents
        return formData.consents.length === 3;
      case 2: // Medical History
        return formData.conditions.length > 0;
      case 3: // Medications
        return formData.hasMedications !== null;
      case 4: // Exclusion Criteria
        return true; // Can proceed regardless of selection
      case 5: // Risk Disclosure
        return formData.understoodRisks;
      case 6: // ID Verification
        return formData.idVerified;
      case 7: // Pharmacy Selection
        return formData.selectedPharmacy !== null;
      case 8: // Strain Selection
        return (
          formData.selectedStrains.length > 0 &&
          formData.selectedStrains.reduce((sum, { amount }) => sum + amount, 0) <=
            100
        );
      case 9: // Payment
        return formData.paymentComplete;
      default:
        return false;
    }
  };

  const renderStep = () => {
    if (processTerminated) {
      return (
        <div className="text-center p-6">
          <h3 className="text-xl font-semibold text-red-600">
            Leider können wir Ihnen kein Cannabis verschreiben
          </h3>
          <p className="text-gray-700 mt-4">
            Aufgrund der von Ihnen angegebenen Informationen können wir Ihnen
            leider kein Cannabis verschreiben. Bitte wenden Sie sich an Ihren
            Arzt für weitere Informationen.
          </p>
        </div>
      );
    }

    switch (currentStep) {
      case 0:
        return (
          <PersonalInfo
            data={formData.personalInfo}
            onUpdate={(data) => updateFormData('personalInfo', data)}
          />
        );
      case 1:
        return (
          <Consent
            consents={formData.consents}
            onUpdate={(data) => updateFormData('consents', data)}
          />
        );
      case 2:
        return (
          <MedicalHistory
            conditions={formData.conditions}
            onUpdate={(data) => updateFormData('conditions', data)}
          />
        );
      case 3:
        return (
          <Medications
            hasMedications={formData.hasMedications}
            medications={formData.medications}
            onUpdate={(data) => updateFormData('hasMedications', data)}
          />
        );
      case 4:
        return (
          <ExclusionCriteria
            selectedCriteria={formData.exclusionCriteria}
            onUpdate={(data) => updateFormData('exclusionCriteria', data)}
          />
        );
      case 5:
        return (
          <RiskDisclosure
            understood={formData.understoodRisks}
            onUpdate={(data) => updateFormData('understoodRisks', data)}
          />
        );
      case 6:
        return (
          <IDVerification
            idDocument={formData.idDocument}
            verified={formData.idVerified}
            onUpdate={(file) => updateFormData('idDocument', file)}
            onVerify={(verified) => updateFormData('idVerified', verified)}
            userName={`${formData.personalInfo.firstName} ${formData.personalInfo.lastName}`}
            userDateOfBirth={formData.personalInfo.dateOfBirth}
          />
        );
      case 7:
        return (
          <PharmacySelection
            selectedPharmacy={formData.selectedPharmacy}
            onSelect={(pharmacy) => updateFormData('selectedPharmacy', pharmacy)}
          />
        );
      case 8:
        return (
          <StrainSelection
            pharmacyId={formData.selectedPharmacy?.id || ''}
            selectedStrains={formData.selectedStrains}
            onUpdate={(strains) => updateFormData('selectedStrains', strains)}
          />
        );
      case 9:
        return <Payment formData={formData} onPaymentComplete={handleComplete} />;
      default:
        return null;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999] flex items-center justify-center">
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black/50 backdrop-blur-sm"
          onClick={onClose}
        />
        <motion.div
          initial={{ scale: 0.95, opacity: 0, y: 20 }}
          animate={{ scale: 1, opacity: 1, y: 0 }}
          exit={{ scale: 0.95, opacity: 0, y: 20 }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 30,
          }}
          className="relative bg-white rounded-2xl w-full max-w-2xl max-h-[90vh] overflow-y-auto mx-4"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="sticky top-0 bg-white px-6 py-4 border-b flex justify-between items-center z-10">
            <h2 className="text-xl font-semibold">Rezept beantragen</h2>
            <button
              onClick={onClose}
              className="p-2 hover:bg-gray-100 rounded-full transition-colors duration-300"
            >
              <X className="w-5 h-5" />
            </button>
          </div>

          {/* Progress Bar */}
          {!processTerminated && (
            <div className="px-6 py-4">
              <div className="flex justify-between mb-2">
                {steps.map((step, index) => (
                  <div
                    key={step}
                    className={`text-xs ${
                      index <= currentStep ? 'text-green-600' : 'text-gray-400'
                    }`}
                  >
                    Schritt {index + 1}
                  </div>
                ))}
              </div>
              <div className="w-full h-2 bg-gray-200 rounded-full">
                <motion.div
                  initial={{ width: 0 }}
                  animate={{
                    width: `${((currentStep + 1) / steps.length) * 100}%`,
                  }}
                  transition={{ duration: 0.3 }}
                  className="h-full bg-green-600 rounded-full"
                />
              </div>
            </div>
          )}

          {/* Content */}
          <div className="p-6">{renderStep()}</div>

          {/* Footer */}
          <div className="sticky bottom-0 bg-white px-6 py-4 border-t flex justify-between">
            <button
              onClick={handleBack}
              className={`px-6 py-2 rounded-lg transition-colors duration-300 ${
                currentStep === 0 || processTerminated
                  ? 'text-gray-400 cursor-not-allowed'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
              disabled={currentStep === 0 || processTerminated}
            >
              Zurück
            </button>
            <button
              onClick={processTerminated ? onClose : handleNext}
              disabled={!canProceed() && !processTerminated}
              className={`px-6 py-2 rounded-lg transition-colors duration-300 ${
                processTerminated
                  ? 'bg-gray-200 text-gray-600 hover:bg-gray-300'
                  : canProceed()
                  ? 'bg-green-600 text-white hover:bg-green-700'
                  : 'bg-gray-200 text-gray-400 cursor-not-allowed'
              }`}
            >
              {processTerminated
                ? 'Schließen'
                : currentStep === steps.length - 1
                ? 'Abschließen'
                : 'Weiter'}
            </button>
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default PrescriptionModal;
