import { Strain } from '../types';

export const strains: Strain[] = [
  {
    id: 'drapalin-301-kukis',
    name: 'Drapalin 30/1 Kukis',
    producer: 'Kukis',
    thcContent: '30%',
    cbdContent: '1%',
    type: 'Indica',
    effects: ['Entspannend', 'Euphorisierend', 'Schmerzlindernd'],
    medicalUse: ['Angststörungen', 'Chronische Schmerzen', 'Appetitlosigkeit'],
    description: 'Eine starke indica-dominierte Sorte mit hoher Potenz, bekannt für entspannende und stimmungsaufhellende Effekte.',
    imageUrl: 'https://medcanonestop.com/wp-content/uploads/2024/07/Drapalin_Kukis.png',
    priceRange: { min: 140, max: 170 },
    availability: true,
    rating: 4.1,
    reviewCount: 64,
    origin: 'Tschechien',
    releaseDate: '2024-05-01',
    createdAt: '2024-03-15T10:00:00Z',
    terpenes: [
      { name: 'Limonen', percentage: 0.6, effects: ['Stimmungsaufhellend', 'Stresslindernd'] },
      { name: 'Myrcen', percentage: 0.4, effects: ['Entspannend', 'Schmerzlindernd'] }
    ],
    taste: ['Süß', 'Kräuter', 'Erde'],
    growInfo: { difficulty: 'Mittel', floweringTime: '9-10 Wochen', yield: '500-600g/m²' },
    labResults: { thcA: '31%', cbn: '0.2%', totalCannabinoids: '32%' }
  },
  {
    id: 'drapalin-271-pink-gas',
    name: 'Drapalin 27/1 Pink Gas',
    producer: 'Pink Gas',
    thcContent: '27%',
    cbdContent: '1%',
    type: 'Indica',
    effects: ['Entspannend', 'Schmerzlindernd', 'Schlaffördernd'],
    medicalUse: ['Schlafstörungen', 'Chronische Schmerzen', 'Stress'],
    description: 'Potente, indica-dominierte Sorte, bekannt für ihre starke entspannende und schlaffördernde Wirkung.',
    imageUrl: 'https://medcanonestop.com/wp-content/uploads/2024/07/Drapalin_PinkGas.png',
    priceRange: { min: 130, max: 160 },
    availability: true,
    rating: 4.3,
    reviewCount: 47,
    origin: 'Tschechien',
    releaseDate: '2024-04-10',
    createdAt: '2024-03-10T14:30:00Z',
    terpenes: [
      { name: 'Caryophyllen', percentage: 0.5, effects: ['Entzündungshemmend', 'Beruhigend'] },
      { name: 'Pinen', percentage: 0.3, effects: ['Konzentrationssteigernd', 'Aufhellend'] }
    ],
    taste: ['Gasig', 'Erde', 'Beeren'],
    growInfo: { difficulty: 'Mittel', floweringTime: '8-9 Wochen', yield: '450-500g/m²' },
    labResults: { thcA: '28%', cbn: '0.1%', totalCannabinoids: '29%' }
  },
  {
    id: 'aurora-221-delahaze',
    name: 'Aurora 22/1 Delahaze',
    producer: 'Aurora',
    thcContent: '22%',
    cbdContent: '1%',
    type: 'Sativa',
    effects: ['Euphorisierend', 'Kreativitätsfördernd', 'Energieverstärkend'],
    medicalUse: ['Depression', 'Stress', 'Chronische Schmerzen'],
    description: 'Delahaze ist bekannt für ihr fruchtiges Aroma und ihre erhebende Wirkung, die Kreativität und Energie steigert.',
    imageUrl: 'https://medcanonestop.com/wp-content/uploads/2024/05/AURORA-22_1_Bluete-1.png',
    priceRange: { min: 120, max: 150 },
    availability: true,
    rating: 4.2,
    reviewCount: 40,
    origin: 'Kanada',
    releaseDate: '2024-06-01',
    createdAt: '2024-04-01T10:00:00Z',
    terpenes: [
      { name: 'Terpinolen', percentage: 0.5, effects: ['Stimmungsaufhellend', 'Stresslindernd'] },
      { name: 'Myrcen', percentage: 0.3, effects: ['Entspannend', 'Schmerzlindernd'] }
    ],
    taste: ['Zitrus', 'Kräuter', 'Erde'],
    growInfo: { difficulty: 'Einfach', floweringTime: '9-10 Wochen', yield: '400-500g/m²' },
    labResults: { thcA: '23%', cbn: '0.1%', totalCannabinoids: '24%' }
  },
  {
    id: 'canify-181-durban-sky',
    name: 'Canify 18/1 Durban Sky',
    producer: 'Canify',
    thcContent: '18%',
    cbdContent: '1%',
    type: 'Sativa',
    effects: ['Energetisierend', 'Stimmungsaufhellend'],
    medicalUse: ['Müdigkeit', 'Depression', 'Stress'],
    description: 'Belebende Sorte, ideal für den Tagesgebrauch mit süßem und würzigem Aroma und erdigen Untertönen.',
    imageUrl: 'https://flowzz.com/_next/image?url=https%3A%2F%2Fflowzz.ams3.digitaloceanspaces.com%2Fcms%2Fd12b6cb4a02b4d7d14d452b66ad8ebc5.png&w=3840&q=75',
    priceRange: { min: 100, max: 130 },
    availability: true,
    rating: 4.0,
    reviewCount: 30,
    origin: 'Uruguay',
    releaseDate: '2024-06-05',
    createdAt: '2024-04-20T10:00:00Z',
    terpenes: [
      { name: 'Terpinolen', percentage: 0.4, effects: ['Stimmungsaufhellend', 'Energieverstärkend'] },
      { name: 'Caryophyllen', percentage: 0.3, effects: ['Schmerzlindernd'] }
    ],
    taste: ['Süß', 'Würzig', 'Erde'],
    growInfo: { difficulty: 'Einfach', floweringTime: '8-9 Wochen', yield: '400-500g/m²' },
    labResults: { thcA: '19%', cbn: '0.1%', totalCannabinoids: '20%' }
  },
  // Continue adding entries for remaining strains
];
