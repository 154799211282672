import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Building2, Search, MapPin, Star } from 'lucide-react';
import type { Pharmacy } from '../../../types';
import { pharmacies } from '../../../data/pharmacies';

interface PharmacySelectionProps {
  selectedPharmacy: Pharmacy | null;
  onSelect: (pharmacy: Pharmacy) => void;
}

const PharmacySelection: React.FC<PharmacySelectionProps> = ({
  selectedPharmacy,
  onSelect,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPharmacies, setFilteredPharmacies] = useState(pharmacies);

  useEffect(() => {
    const filtered = pharmacies.filter(pharmacy =>
      pharmacy.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pharmacy.address.city.toLowerCase().includes(searchTerm.toLowerCase()) ||
      pharmacy.address.postalCode.includes(searchTerm)
    );
    setFilteredPharmacies(filtered);
  }, [searchTerm]);

  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <Building2 className="w-8 h-8 text-blue-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">Apotheke auswählen</h3>
        <p className="text-gray-600 mt-2">
          Wähle eine Apotheke für dein Rezept aus
        </p>
      </div>

      <div className="relative">
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Suche nach PLZ, Stadt oder Apotheke..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
      </div>

      <div className="space-y-4 max-h-[400px] overflow-y-auto">
        {filteredPharmacies.map((pharmacy) => (
          <motion.button
            key={pharmacy.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            onClick={() => onSelect(pharmacy)}
            className={`w-full p-4 rounded-xl border-2 transition-all duration-300 ${
              selectedPharmacy?.id === pharmacy.id
                ? 'border-blue-500 bg-blue-50'
                : 'border-gray-200 hover:border-blue-500'
            }`}
          >
            <div className="flex items-start gap-4">
              <div className={`p-3 rounded-lg ${
                selectedPharmacy?.id === pharmacy.id ? 'bg-blue-100' : 'bg-gray-100'
              }`}>
                <Building2 className={`w-6 h-6 ${
                  selectedPharmacy?.id === pharmacy.id ? 'text-blue-600' : 'text-gray-600'
                }`} />
              </div>
              <div className="flex-1 text-left">
                <h4 className={`font-medium ${
                  selectedPharmacy?.id === pharmacy.id ? 'text-blue-900' : 'text-gray-900'
                }`}>
                  {pharmacy.name}
                </h4>
                <div className="flex items-center gap-2 text-sm text-gray-500 mt-1">
                  <MapPin className="w-4 h-4" />
                  <span>{pharmacy.address.street}, {pharmacy.address.postalCode} {pharmacy.address.city}</span>
                </div>
                <div className="flex items-center gap-4 mt-2">
                  <div className="flex items-center gap-1">
                    <Star className="w-4 h-4 text-yellow-400" />
                    <span className="text-sm text-gray-600">{pharmacy.rating}</span>
                  </div>
                  <span className="text-sm text-gray-600">{pharmacy.strainCount} Sorten verfügbar</span>
                </div>
              </div>
            </div>
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default PharmacySelection;