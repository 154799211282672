import { Pharmacy } from '../types';

export const pharmacies: Pharmacy[] = [
  {
    id: 'roentgen-apotheke-wuerzburg',
    name: 'Röntgen-Apotheke Würzburg',
    address: {
      street: 'Juliuspromenade 58',
      city: 'Würzburg',
      postalCode: '97070',
      state: 'Bayern',
      latitude: '49.7969804',
      longitude: '9.9306289',
    },
    contact: {
      phone: '+49 0931 3593380',
      email: 'info@roentgen-apotheke.com',
      website: 'https://www.apotheke-wuerzburg.de',
    },
    rating: 3.7,
    deliveryAvailable: true,
    strainCount: 174,
    specializations: ['Cannabis-Beratung', 'Hauslieferung', 'Medikamenten-Vorbestellung'],
    openingHours: {
      'Montag-Freitag': '08:00-19:00',
      'Samstag': '09:00-14:00',
      'Sonntag': 'Geschlossen',
    },
    imageUrl: 'https://s3-media0.fl.yelpcdn.com/bphoto/ftTJZB-yi1TNysTFuIw96g/348s.jpg'
  },
  // Add more pharmacies as needed
];