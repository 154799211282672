import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../lib/api';
import { initializeWebSocket, closeWebSocket } from '../lib/websocket';

interface User {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  address?: string;
  role: 'doctor' | 'patient';
}

interface AuthContextType {
  user: User | null;
  setUser: (user: User | null) => void;
  loading: boolean;
  error: string | null;
  login: (email: string, password: string) => Promise<void>;
  register: (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string
  ) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const initAuth = async () => {
      const token = localStorage.getItem('authToken');
      const userData = localStorage.getItem('userData');
      if (token && userData) {
        try {
          api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const parsedUser = JSON.parse(userData);
          if (parsedUser && (parsedUser.role === 'doctor' || parsedUser.role === 'patient')) {
            setUser(parsedUser);
            initializeWebSocket(token);
          } else {
            throw new Error('Invalid user role');
          }
        } catch (error) {
          console.error('Auth initialization error:', error);
          localStorage.removeItem('authToken');
          localStorage.removeItem('userData');
          delete api.defaults.headers.common['Authorization'];
          closeWebSocket();
        }
      }
      setLoading(false);
    };

    initAuth();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await api.post('/auth/login', { 
        email, 
        password 
      });
      
      const { token, user } = response.data;
      
      if (!user.role || !['doctor', 'patient'].includes(user.role)) {
        throw new Error('Invalid user role received');
      }

      localStorage.setItem('authToken', token);
      localStorage.setItem('userData', JSON.stringify(user));
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      initializeWebSocket(token);
    } catch (err: any) {
      const message = err.response?.data?.message || 'Anmeldung fehlgeschlagen';
      setError(message);
      throw new Error(message);
    } finally {
      setLoading(false);
    }
  };

  const register = async (
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    password: string
  ) => {
    try {
      setLoading(true);
      setError(null);

      const response = await api.post('/auth/register', {
        firstName,
        lastName,
        email,
        phone,
        password
      });

      const { token, user } = response.data;
      
      if (!user.role || !['doctor', 'patient'].includes(user.role)) {
        throw new Error('Invalid user role received');
      }

      localStorage.setItem('authToken', token);
      localStorage.setItem('userData', JSON.stringify(user));
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setUser(user);
      initializeWebSocket(token);
    } catch (err: any) {
      const message = err.response?.data?.message || 'Registrierung fehlgeschlagen';
      setError(message);
      throw new Error(message);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      await api.post('/auth/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userData');
      delete api.defaults.headers.common['Authorization'];
      setUser(null);
      closeWebSocket();
    }
  };

  const clearError = () => setError(null);

  const value = {
    user,
    setUser,
    loading,
    error,
    login,
    register,
    logout,
    clearError
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
