import React from 'react';
import { motion } from 'framer-motion';
import { Shield, CheckSquare } from 'lucide-react';

interface ConsentProps {
  consents: string[];
  onUpdate: (consents: string[]) => void;
}

const consentItems = [
  {
    id: 'truthful',
    text: 'Ich bestätige, dass alle Angaben wahrheitsgemäß und vollständig sind. Falsche Angaben können die Behandlung beeinträchtigen.'
  },
  {
    id: 'data-sharing',
    text: 'Ich stimme zu, dass meine Gesundheitsdaten mit Ärzten und Apotheken geteilt werden dürfen, um eine optimale Versorgung sicherzustellen und Missbrauch zu verhindern.'
  },
  {
    id: 'terms',
    text: 'Ich akzeptiere die Datenschutzerklärung und die Allgemeinen Geschäftsbedingungen der Canniva-Plattform.'
  }
];

const Consent: React.FC<ConsentProps> = ({ consents, onUpdate }) => {
  const toggleConsent = (id: string) => {
    if (consents.includes(id)) {
      onUpdate(consents.filter(c => c !== id));
    } else {
      onUpdate([...consents, id]);
    }
  };

  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <Shield className="w-8 h-8 text-green-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">Einwilligungen</h3>
        <p className="text-gray-600 mt-2">
          Bitte überprüfe und stimme den folgenden Einwilligungen zu
        </p>
      </div>

      <div className="space-y-4">
        {consentItems.map((item, index) => (
          <motion.div
            key={item.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <label className="flex items-start gap-4 p-4 rounded-xl border-2 cursor-pointer transition-all duration-300 hover:border-green-500">
              <div className="flex-shrink-0 mt-1">
                <div
                  className={`w-6 h-6 rounded border-2 flex items-center justify-center transition-colors duration-300 ${
                    consents.includes(item.id)
                      ? 'bg-green-600 border-green-600'
                      : 'border-gray-300'
                  }`}
                  onClick={() => toggleConsent(item.id)}
                >
                  {consents.includes(item.id) && (
                    <CheckSquare className="w-4 h-4 text-white" />
                  )}
                </div>
              </div>
              <span className="text-gray-700 text-sm">{item.text}</span>
            </label>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default Consent;
