import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Leaf, Search, AlertCircle } from 'lucide-react';
import type { Strain } from '../../../types';
import { strains } from '../../../data/strains';

interface StrainSelectionProps {
  pharmacyId: string;
  selectedStrains: { strain: Strain; amount: number }[];
  onUpdate: (strains: { strain: Strain; amount: number }[]) => void;
}

const MAX_TOTAL_AMOUNT = 100; // Maximum total amount in grams

const StrainSelection: React.FC<StrainSelectionProps> = ({
  pharmacyId,
  selectedStrains,
  onUpdate,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState<string | null>(null);

  // Filter strains based on pharmacy and search term
  const availableStrains = strains.filter(strain =>
    strain.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    strain.producer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalAmount = selectedStrains.reduce((sum, { amount }) => sum + amount, 0);

  const handleStrainSelect = (strain: Strain, amount: number) => {
    const existingIndex = selectedStrains.findIndex(s => s.strain.id === strain.id);
    const newAmount = amount > 0 ? amount : 0;

    let updatedStrains;
    if (existingIndex >= 0) {
      if (newAmount === 0) {
        updatedStrains = selectedStrains.filter((_, index) => index !== existingIndex);
      } else {
        updatedStrains = [...selectedStrains];
        updatedStrains[existingIndex] = { strain, amount: newAmount };
      }
    } else {
      updatedStrains = [...selectedStrains, { strain, amount: newAmount }];
    }

    const newTotal = updatedStrains.reduce((sum, { amount }) => sum + amount, 0);
    
    if (newTotal > MAX_TOTAL_AMOUNT) {
      setError(`Die Gesamtmenge darf ${MAX_TOTAL_AMOUNT}g nicht überschreiten`);
      return;
    }

    setError(null);
    onUpdate(updatedStrains);
  };

  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <Leaf className="w-8 h-8 text-green-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">Cannabis-Sorten auswählen</h3>
        <p className="text-gray-600 mt-2">
          Wähle bis zu {MAX_TOTAL_AMOUNT}g Cannabis aus den verfügbaren Sorten
        </p>
      </div>

      <div className="relative">
        <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        <input
          type="text"
          placeholder="Suche nach Sorten..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
        />
      </div>

      {error && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex items-center gap-2 p-4 bg-red-50 text-red-700 rounded-lg"
        >
          <AlertCircle className="w-5 h-5 flex-shrink-0" />
          <p>{error}</p>
        </motion.div>
      )}

      <div className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
        <span className="text-gray-700">Gesamtmenge:</span>
        <span className="font-medium text-gray-900">{totalAmount}g / {MAX_TOTAL_AMOUNT}g</span>
      </div>

      <div className="space-y-4 max-h-[400px] overflow-y-auto">
        {availableStrains.map((strain) => {
          const selected = selectedStrains.find(s => s.strain.id === strain.id);
          const amount = selected?.amount || 0;

          return (
            <motion.div
              key={strain.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className={`p-4 rounded-xl border-2 transition-all duration-300 ${
                amount > 0 ? 'border-green-500 bg-green-50' : 'border-gray-200'
              }`}
            >
              <div className="flex items-start gap-4">
                <div className={`p-3 rounded-lg ${
                  amount > 0 ? 'bg-green-100' : 'bg-gray-100'
                }`}>
                  <Leaf className={`w-6 h-6 ${
                    amount > 0 ? 'text-green-600' : 'text-gray-600'
                  }`} />
                </div>
                <div className="flex-1">
                  <h4 className="font-medium text-gray-900">{strain.name}</h4>
                  <p className="text-sm text-gray-500">{strain.producer}</p>
                  <div className="flex items-center gap-4 mt-2">
                    <span className="text-sm text-gray-600">THC: {strain.thcContent}</span>
                    <span className="text-sm text-gray-600">CBD: {strain.cbdContent}</span>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    min="0"
                    max={MAX_TOTAL_AMOUNT}
                    value={amount}
                    onChange={(e) => handleStrainSelect(strain, parseInt(e.target.value) || 0)}
                    className="w-20 px-3 py-1 rounded-lg border border-gray-300 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  />
                  <span className="text-gray-600">g</span>
                </div>
              </div>
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default StrainSelection;