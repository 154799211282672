import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

// Create a custom error type
export class ApiError extends Error {
  constructor(
    public status: number,
    public message: string,
    public data?: any
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

// Create and configure axios instance
const api: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL || 'http://localhost:5000',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (response.data?.token) {
      localStorage.setItem('authToken', response.data.token);
      api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
    }
    return response;
  },
  async (error: AxiosError<{ message?: string }>) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('authToken');
      localStorage.removeItem('userData');
      delete api.defaults.headers.common['Authorization'];
      window.location.href = '/';
    }
    
    // Create a user-friendly error message
    const errorMessage = error.response?.data?.message || 'Ein Fehler ist aufgetreten';
    throw new ApiError(
      error.response?.status || 500,
      errorMessage,
      error.response?.data
    );
  }
);

// Type-safe API methods
export const apiClient = {
  get: async <T>(url: string, config = {}) => {
    const response = await api.get<T>(url, config);
    return response.data;
  },
  
  post: async <T>(url: string, data?: any, config = {}) => {
    const response = await api.post<T>(url, data, config);
    return response.data;
  },
  
  put: async <T>(url: string, data?: any, config = {}) => {
    const response = await api.put<T>(url, data, config);
    return response.data;
  },
  
  delete: async <T>(url: string, config = {}) => {
    const response = await api.delete<T>(url, config);
    return response.data;
  },
};

export default api;