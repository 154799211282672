import React from 'react';
import { motion } from 'framer-motion';
import { Pill, Check, X } from 'lucide-react';

interface MedicationsProps {
  hasMedications: boolean | null;
  medications: string[];
  onUpdate: (value: boolean) => void;
}

const Medications: React.FC<MedicationsProps> = ({ hasMedications, onUpdate }) => {
  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <Pill className="w-8 h-8 text-green-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">Aktuelle Medikamente</h3>
        <p className="text-gray-600 mt-2">
          Nimmst du derzeit regelmäßig Medikamente ein?
        </p>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <motion.button
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.2 }}
          onClick={() => onUpdate(true)}
          className={`p-6 rounded-xl border-2 transition-all duration-300 ${
            hasMedications === true
              ? 'border-green-500 bg-green-50'
              : 'border-gray-200 hover:border-green-500'
          }`}
        >
          <div className="flex flex-col items-center gap-4">
            <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
              hasMedications === true ? 'bg-green-100' : 'bg-gray-100'
            }`}>
              <Check className={`w-6 h-6 ${
                hasMedications === true ? 'text-green-600' : 'text-gray-400'
              }`} />
            </div>
            <span className={hasMedications === true ? 'text-green-700' : 'text-gray-900'}>
              Ja
            </span>
          </div>
        </motion.button>

        <motion.button
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: 0.3 }}
          onClick={() => onUpdate(false)}
          className={`p-6 rounded-xl border-2 transition-all duration-300 ${
            hasMedications === false
              ? 'border-green-500 bg-green-50'
              : 'border-gray-200 hover:border-green-500'
          }`}
        >
          <div className="flex flex-col items-center gap-4">
            <div className={`w-12 h-12 rounded-full flex items-center justify-center ${
              hasMedications === false ? 'bg-green-100' : 'bg-gray-100'
            }`}>
              <X className={`w-6 h-6 ${
                hasMedications === false ? 'text-green-600' : 'text-gray-400'
              }`} />
            </div>
            <span className={hasMedications === false ? 'text-green-700' : 'text-gray-900'}>
              Nein
            </span>
          </div>
        </motion.button>
      </div>
    </div>
  );
};

export default Medications;
