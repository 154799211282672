import React from 'react';
import { motion } from 'framer-motion';
import { AlertTriangle, CheckSquare } from 'lucide-react';

interface RiskDisclosureProps {
  understood: boolean;
  onUpdate: (understood: boolean) => void;
}

const RiskDisclosure: React.FC<RiskDisclosureProps> = ({ understood, onUpdate }) => {
  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="w-16 h-16 bg-yellow-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <AlertTriangle className="w-8 h-8 text-yellow-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">Informationspflicht</h3>
        <p className="text-gray-600 mt-2">
          Bitte lies die folgenden Informationen aufmerksam durch
        </p>
      </div>

      <div className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-yellow-50 p-6 rounded-xl"
        >
          <h4 className="font-semibold text-yellow-800 mb-4">Wichtige Hinweise</h4>
          <div className="space-y-4 text-yellow-700">
            <p>
              Die Anwendung von medizinischem Cannabis kann mit verschiedenen Risiken und Nebenwirkungen verbunden sein:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Müdigkeit und verminderte Reaktionsfähigkeit</li>
              <li>Beeinträchtigung der Fahrtüchtigkeit</li>
              <li>Psychische Abhängigkeit bei längerer Anwendung</li>
              <li>Wechselwirkungen mit anderen Medikamenten</li>
              <li>Vorübergehende Gedächtnis- und Konzentrationsstörungen</li>
            </ul>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-blue-50 p-6 rounded-xl"
        >
          <h4 className="font-semibold text-blue-800 mb-4">Therapeutische Vorteile</h4>
          <div className="space-y-4 text-blue-700">
            <p>
              Medizinisches Cannabis kann bei verschiedenen Erkrankungen therapeutisch wirksam sein:
            </p>
            <ul className="list-disc list-inside space-y-2">
              <li>Schmerzlinderung bei chronischen Schmerzen</li>
              <li>Reduzierung von Spastik bei MS</li>
              <li>Appetitsteigerung bei bestimmten Erkrankungen</li>
              <li>Unterstützung bei Schlafstörungen</li>
              <li>Anxiolytische und antidepressive Wirkung</li>
            </ul>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <label className="flex items-start gap-4 p-4 rounded-xl border-2 cursor-pointer transition-all duration-300 hover:border-green-500">
            <div className="flex-shrink-0 mt-1">
              <div
                className={`w-6 h-6 rounded border-2 flex items-center justify-center transition-colors duration-300 ${
                  understood
                    ? 'bg-green-600 border-green-600'
                    : 'border-gray-300'
                }`}
                onClick={() => onUpdate(!understood)}
              >
                {understood && (
                  <CheckSquare className="w-4 h-4 text-white" />
                )}
              </div>
            </div>
            <span className="text-gray-700 text-sm">
              Ich habe die Informationen zu den Vorteilen und Risiken der Behandlung mit medizinischem Cannabis gelesen und verstanden. Mir ist bewusst, dass die Behandlung individuell unterschiedlich wirken kann und regelmäßige Kontrolluntersuchungen erforderlich sind.
            </span>
          </label>
        </motion.div>
      </div>
    </div>
  );
};

export default RiskDisclosure;
