import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Menu,
  X,
  Leaf,
  Building2,
  FileText,
  User,
  LayoutDashboard,
  Settings,
  Eye,
  EyeOff,
  ChevronDown,
  Target
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import PrescriptionModal from './PrescriptionRequest/PrescriptionModal';
import LoginModal from './auth/LoginModal';
import logo from './assets/cannvialogo.png';

interface NavItem {
  path: string;
  label: string;
  icon: React.ComponentType<any>;
  activeColor: string;
}

const Navbar: React.FC = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [prescriptionModalOpen, setPrescriptionModalOpen] = useState(false);
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [showFullName, setShowFullName] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    setMobileMenuOpen(false);
    setDropdownOpen(false);
  }, [location.pathname]);

  const isActive = (path: string) => location.pathname === path;

  const navItems: NavItem[] = [
    { path: '/strains', label: 'Sorten', icon: Leaf, activeColor: 'green' },
    { path: '/pharmacies', label: 'Apotheken', icon: Building2, activeColor: 'blue' },
    { path: '/strain-finder', label: 'Strain Finder', icon: Target, activeColor: 'pink' },
  ];

  const handleDashboardClick = () => {
    navigate('/dashboard');
    window.scrollTo(0, 0);
    setMobileMenuOpen(false);
  };

  const handleSettingsClick = () => {
    navigate('/settings');
    window.scrollTo(0, 0);
    setDropdownOpen(false);
    setMobileMenuOpen(false);
  };

  const formatName = (
    firstName?: string,
    lastName?: string,
    role?: string
  ) => {
    if (!firstName || !lastName || !role) return '';
    return !showFullName
      ? role === 'doctor'
        ? `Dr. ${firstName.charAt(0)}. ${lastName.charAt(0)}.`
        : `${firstName.charAt(0)}. ${lastName.charAt(0)}.`
      : role === 'doctor'
        ? `Dr. ${firstName} ${lastName}`
        : `${firstName} ${lastName}`;
  };

  return (
    <>
      <motion.nav
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ type: 'spring', stiffness: 75, damping: 20 }}
        className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
          scrolled 
            ? 'bg-white/90 backdrop-blur-lg shadow-lg'
            : 'bg-transparent'
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 md:h-20 items-center">
            {/* Logo - Hidden on mobile */}
            <div className="hidden md:flex items-center">
              <Link to="/" className="flex-shrink-0 flex items-center">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                >
                  <img
                    src={logo}
                    alt="Canniva Logo"
                    className="h-8 w-auto"
                  />
                </motion.div>
              </Link>

              {/* Desktop Navigation Links */}
              <div className="ml-10 flex space-x-8">
                {navItems.map((item) => (
                  <Link
                    key={item.path}
                    to={item.path}
                    className="group relative px-3 py-2"
                  >
                    <span className={`inline-flex items-center text-sm font-medium transition-colors duration-300 ${
                      isActive(item.path)
                        ? `text-${item.activeColor}-600`
                        : 'text-gray-600 group-hover:text-gray-900'
                    }`}>
                      <item.icon className="w-4 h-4 mr-2" />
                      {item.label}
                    </span>
                    <span className={`absolute bottom-0 left-0 w-full h-0.5 transform origin-left transition-transform duration-300 ${
                      isActive(item.path)
                        ? `scale-x-100 bg-${item.activeColor}-600`
                        : 'scale-x-0 bg-gray-300 group-hover:scale-x-100'
                    }`} />
                  </Link>
                ))}
              </div>
            </div>

            {/* Mobile Title */}
            <div className="md:hidden flex items-center">
              <span className="text-lg font-semibold text-gray-900">Canniva</span>
            </div>

            {/* User Actions */}
            <div className="flex items-center gap-2 md:gap-4">
              {user ? (
                <>
                  {user.role === 'doctor' ? (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={handleDashboardClick}
                      className="hidden md:inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 transition-all duration-300 transform shadow-lg shadow-green-200/50"
                    >
                      <LayoutDashboard className="w-4 h-4 mr-2" />
                      Dashboard
                    </motion.button>
                  ) : (
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setPrescriptionModalOpen(true)}
                      className="hidden md:inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 transition-all duration-300 transform shadow-lg shadow-green-200/50"
                    >
                      <FileText className="w-4 h-4 mr-2" />
                      Patient werden
                    </motion.button>
                  )}

                  <div className="relative">
                    <motion.button
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setDropdownOpen(!dropdownOpen)}
                      className="flex items-center gap-2 px-3 md:px-4 py-2 text-sm font-medium rounded-xl bg-gray-100 hover:bg-gray-200 transition-all duration-300"
                    >
                      <User className="w-4 h-4" />
                      <span className="hidden md:inline">{formatName(user.firstName, user.lastName, user.role)}</span>
                      <ChevronDown className={`w-4 h-4 transition-transform duration-300 ${dropdownOpen ? 'rotate-180' : ''}`} />
                    </motion.button>

                    <AnimatePresence>
                      {dropdownOpen && (
                        <motion.div
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: 10 }}
                          transition={{ duration: 0.2 }}
                          className="absolute right-0 mt-2 w-48 bg-white rounded-xl shadow-lg overflow-hidden z-50"
                        >
                          <motion.button
                            whileHover={{ x: 4, backgroundColor: '#f3f4f6' }}
                            onClick={handleSettingsClick}
                            className="flex items-center w-full px-4 py-3 text-left text-sm text-gray-700"
                          >
                            <Settings className="w-4 h-4 mr-2" />
                            Einstellungen
                          </motion.button>
                          <motion.button
                            whileHover={{ x: 4, backgroundColor: '#f3f4f6' }}
                            onClick={() => {
                              logout();
                              setDropdownOpen(false);
                              setMobileMenuOpen(false);
                            }}
                            className="flex items-center w-full px-4 py-3 text-left text-sm text-gray-700"
                          >
                            <X className="w-4 h-4 mr-2" />
                            Abmelden
                          </motion.button>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    onClick={() => setShowFullName(!showFullName)}
                    className="hidden md:flex p-2 hover:bg-gray-100 rounded-full transition-colors duration-200"
                  >
                    {showFullName ? (
                      <EyeOff className="w-4 h-4 text-gray-500" />
                    ) : (
                      <Eye className="w-4 h-4 text-gray-500" />
                    )}
                  </motion.button>
                </>
              ) : (
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => setLoginModalOpen(true)}
                  className="inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl text-white bg-gradient-to-r from-green-600 to-emerald-600 hover:from-green-700 hover:to-emerald-700 transition-all duration-300 transform shadow-lg shadow-green-200/50"
                >
                  <User className="w-4 h-4 mr-2" />
                  <span className="hidden md:inline">Anmelden</span>
                </motion.button>
              )}

              {/* Mobile Menu Button */}
              <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                className="md:hidden p-2 rounded-lg text-gray-500 hover:text-gray-700 hover:bg-gray-100 transition-colors duration-200"
              >
                {mobileMenuOpen ? (
                  <X className="w-6 h-6" />
                ) : (
                  <Menu className="w-6 h-6" />
                )}
              </motion.button>
            </div>
          </div>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {mobileMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden bg-white border-t"
            >
              <div className="px-4 py-6 space-y-4">
                {navItems.map((item, index) => (
                  <motion.div
                    key={item.path}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                  >
                    <Link
                      to={item.path}
                      className={`flex items-center px-4 py-3 rounded-xl transition-all duration-300 ${
                        isActive(item.path)
                          ? `bg-${item.activeColor}-50 text-${item.activeColor}-600`
                          : 'text-gray-700 hover:bg-gray-50'
                      }`}
                      onClick={() => setMobileMenuOpen(false)}
                    >
                      <item.icon className="w-5 h-5 mr-3" />
                      {item.label}
                    </Link>
                  </motion.div>
                ))}

                {user?.role === 'doctor' && (
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                  >
                    <button
                      onClick={handleDashboardClick}
                      className="w-full flex items-center px-4 py-3 rounded-xl text-green-600 hover:bg-green-50 transition-all duration-300"
                    >
                      <LayoutDashboard className="w-5 h-5 mr-3" />
                      Dashboard
                    </button>
                  </motion.div>
                )}

                {user?.role === 'patient' && (
                  <motion.div
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                  >
                    <button
                      onClick={() => {
                        setPrescriptionModalOpen(true);
                        setMobileMenuOpen(false);
                      }}
                      className="w-full flex items-center px-4 py-3 rounded-xl text-green-600 hover:bg-green-50 transition-all duration-300"
                    >
                      <FileText className="w-5 h-5 mr-3" />
                      Rezept beantragen
                    </button>
                  </motion.div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.nav>

      {/* Modals */}
      {user && user.role === 'patient' && (
        <PrescriptionModal
          isOpen={prescriptionModalOpen}
          onClose={() => setPrescriptionModalOpen(false)}
        />
      )}

      <LoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </>
  );
};

export default Navbar;
