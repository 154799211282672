import { lazy, Suspense } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import SmoothLayout from './components/layout/SmoothLayout';
import LoadingSpinner from './components/ui/LoadingSpinner';
import { useAuth } from './contexts/AuthContext';
import ErrorBoundary from './components/ErrorBoundary';
import CookieConsent from './components/CookieConsent';

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const Strains = lazy(() => import('./pages/Strains'));
const StrainFinder = lazy(() => import('./pages/StrainFinder')); // Add StrainFinder
const Pharmacies = lazy(() => import('./pages/Pharmacies'));
const About = lazy(() => import('./pages/company/About'));
const Contact = lazy(() => import('./pages/company/Contact'));
const Instagram = lazy(() => import('./pages/company/Instagram'));
const LegalizationFAQ = lazy(() => import('./pages/resources/LegalizationFAQ'));
const CannivaFAQ = lazy(() => import('./pages/resources/CannivaFAQ'));
const CommunityGuidelines = lazy(() => import('./pages/resources/CommunityGuidelines'));
const Imprint = lazy(() => import('./pages/legal/Imprint'));
const Privacy = lazy(() => import('./pages/legal/Privacy'));
const Terms = lazy(() => import('./pages/legal/Terms'));
const MedicalConsultation = lazy(() => import('./pages/medical/MedicalConsultation'));
const PatientInfo = lazy(() => import('./pages/medical/PatientInfo'));
const Profile = lazy(() => import('./pages/Profile'));
const Settings = lazy(() => import('./pages/Settings'));
const DoctorDashboard = lazy(() => import('./pages/dashboard/DoctorDashboard'));
const BecomePartner = lazy(() => import('./pages/company/BecomePartner'));
const Jobs = lazy(() => import('./pages/company/Jobs'));
const NotFound = lazy(() => import('./pages/NotFound'));

// Protected Route Component
const ProtectedRoute = ({ children, allowedRole }: { children: React.ReactNode; allowedRole: string }) => {
  const { user } = useAuth();
  
  if (!user) {
    return <Navigate to="/" replace />;
  }

  if (allowedRole && user.role !== allowedRole) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

const App = () => {
  const location = useLocation();

  return (
    <ErrorBoundary>
      {location.pathname === '/dashboard' ? (
        // Direct route for dashboard without layout
        <Suspense fallback={<LoadingSpinner />}>
          <Routes>
            <Route path="/dashboard" element={
              <ProtectedRoute allowedRole="doctor">
                <DoctorDashboard />
              </ProtectedRoute>
            } />
          </Routes>
        </Suspense>
      ) : (
        // Routes wrapped with SmoothLayout
        <SmoothLayout>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/strains" element={<Strains />} />
              <Route path="/strain-finder" element={<StrainFinder />} /> {/* Add StrainFinder route */}
              <Route path="/pharmacies" element={<Pharmacies />} />
              <Route path="/profile" element={
                <ProtectedRoute allowedRole="">
                  <Profile />
                </ProtectedRoute>
              } />
              <Route path="/settings" element={
                <ProtectedRoute allowedRole="">
                  <Settings />
                </ProtectedRoute>
              } />
              {/* Company */}
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/instagram" element={<Instagram />} />
              <Route path="/become-partner" element={<BecomePartner />} />
              <Route path="/jobs" element={<Jobs />} />
              {/* Resources */}
              <Route path="/faq-legalization" element={<LegalizationFAQ />} />
              <Route path="/faq-Canniva" element={<CannivaFAQ />} />
              <Route path="/community-guidelines" element={<CommunityGuidelines />} />
              {/* Legal */}
              <Route path="/imprint" element={<Imprint />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              {/* Medical */}
              <Route path="/medical-consultation" element={<MedicalConsultation />} />
              <Route path="/patient-info" element={<PatientInfo />} />
              {/* 404 Page - Must be last */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <CookieConsent />
        </SmoothLayout>
      )}
    </ErrorBoundary>
  );
};

export default App;