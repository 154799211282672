import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Cookie, Shield, X, ChevronDown, ChevronUp } from 'lucide-react';
import { Link } from 'react-router-dom';

interface CookieSettings {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
}

const CookieConsent: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [settings, setSettings] = useState<CookieSettings>({
    necessary: true, // Always required
    analytics: false,
    marketing: false
  });

  useEffect(() => {
    // Check if user has already made a choice
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAcceptAll = () => {
    setSettings({
      necessary: true,
      analytics: true,
      marketing: true
    });
    saveConsent({
      necessary: true,
      analytics: true,
      marketing: true
    });
  };

  const handleAcceptSelected = () => {
    saveConsent(settings);
  };

  const saveConsent = (selectedSettings: CookieSettings) => {
    localStorage.setItem('cookieConsent', JSON.stringify({
      ...selectedSettings,
      timestamp: new Date().toISOString()
    }));
    setIsVisible(false);
    
    // Hier können die entsprechenden Cookies gesetzt oder entfernt werden
    if (selectedSettings.analytics) {
      // Analytics-Cookies aktivieren
    }
    if (selectedSettings.marketing) {
      // Marketing-Cookies aktivieren
    }
  };

  if (!isVisible) return null;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }}
        className="fixed bottom-0 left-0 right-0 z-50 p-4 bg-white border-t shadow-lg"
      >
        <div className="max-w-7xl mx-auto">
          <div className="flex items-start justify-between gap-4">
            <div className="flex items-start gap-4">
              <div className="p-2 bg-blue-100 rounded-lg">
                <Cookie className="w-6 h-6 text-blue-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900 mb-2">
                  Wir respektieren Ihre Privatsphäre
                </h3>
                <p className="text-gray-600 text-sm max-w-2xl">
                  Wir verwenden Cookies, um Ihre Erfahrung auf unserer Website zu verbessern 
                  und Ihnen personalisierte Inhalte anzubieten. Sie können selbst entscheiden, 
                  welche Cookies Sie akzeptieren möchten.
                </p>
              </div>
            </div>

            <button
              onClick={() => setShowDetails(!showDetails)}
              className="p-2 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              aria-label={showDetails ? "Details ausblenden" : "Details anzeigen"}
            >
              {showDetails ? (
                <ChevronUp className="w-5 h-5 text-gray-500" />
              ) : (
                <ChevronDown className="w-5 h-5 text-gray-500" />
              )}
            </button>
          </div>

          <AnimatePresence>
            {showDetails && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                className="mt-4 overflow-hidden"
              >
                <div className="space-y-4 p-4 bg-gray-50 rounded-xl">
                  {/* Necessary Cookies */}
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="font-medium text-gray-900">Notwendige Cookies</div>
                      <p className="text-sm text-gray-600">
                        Diese Cookies sind für die Grundfunktionen der Website erforderlich
                      </p>
                    </div>
                    <div className="bg-gray-200 px-3 py-1 rounded text-sm">
                      Erforderlich
                    </div>
                  </div>

                  {/* Analytics Cookies */}
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="font-medium text-gray-900">Analyse-Cookies</div>
                      <p className="text-sm text-gray-600">
                        Helfen uns zu verstehen, wie Besucher mit der Website interagieren
                      </p>
                    </div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={settings.analytics}
                        onChange={(e) => setSettings(prev => ({
                          ...prev,
                          analytics: e.target.checked
                        }))}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                  </div>

                  {/* Marketing Cookies */}
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="font-medium text-gray-900">Marketing-Cookies</div>
                      <p className="text-sm text-gray-600">
                        Werden verwendet, um personalisierte Werbung anzuzeigen
                      </p>
                    </div>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={settings.marketing}
                        onChange={(e) => setSettings(prev => ({
                          ...prev,
                          marketing: e.target.checked
                        }))}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600"></div>
                    </label>
                  </div>
                </div>

                <div className="mt-4 text-sm text-gray-600">
                  Weitere Informationen finden Sie in unserer{' '}
                  <Link to="/privacy" className="text-blue-600 hover:underline">
                    Datenschutzerklärung
                  </Link>
                  .
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          <div className="mt-6 flex flex-col sm:flex-row gap-4 justify-end">
            <button
              onClick={handleAcceptSelected}
              className="px-6 py-2 border-2 border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors duration-300"
            >
              Auswahl bestätigen
            </button>
            <button
              onClick={handleAcceptAll}
              className="px-6 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors duration-300"
            >
              Alle akzeptieren
            </button>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default CookieConsent;