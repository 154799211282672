import React from 'react';
import { motion } from 'framer-motion';
import { Activity, Brain, Heart, Stethoscope } from 'lucide-react';

interface MedicalHistoryProps {
  conditions: string[];
  onUpdate: (conditions: string[]) => void;
}

const conditions = [
  'Schlafstörungen',
  'Chronische Schmerzen',
  'Neuropathische Schmerzen',
  'Migräne',
  'ADHS',
  'Krebserkrankung',
  'Hauterkrankung',
  'Angststörungen',
  'Depression',
  'Appetitlosigkeit',
  'Multiple Sklerose',
  'Epilepsie'
];

const MedicalHistory: React.FC<MedicalHistoryProps> = ({ conditions: selectedConditions, onUpdate }) => {
  const toggleCondition = (condition: string) => {
    if (selectedConditions.includes(condition)) {
      onUpdate(selectedConditions.filter(c => c !== condition));
    } else {
      onUpdate([...selectedConditions, condition]);
    }
  };

  const getIcon = (index: number) => {
    switch (index % 4) {
      case 0:
        return <Activity className="w-6 h-6 text-green-600" />;
      case 1:
        return <Brain className="w-6 h-6 text-green-600" />;
      case 2:
        return <Heart className="w-6 h-6 text-green-600" />;
      default:
        return <Stethoscope className="w-6 h-6 text-green-600" />;
    }
  };

  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 200 }}
          className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <Stethoscope className="w-8 h-8 text-green-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">Medizinische Vorgeschichte</h3>
        <p className="text-gray-600 mt-2">
          Wähle alle Erkrankungen aus, die auf dich zutreffen
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {conditions.map((condition, index) => (
          <motion.button
            key={condition}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => toggleCondition(condition)}
            className={`p-4 rounded-xl border-2 transition-all duration-300 flex items-center gap-4 ${
              selectedConditions.includes(condition)
                ? 'border-green-500 bg-green-50'
                : 'border-gray-200 hover:border-green-500'
            }`}
          >
            <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
              selectedConditions.includes(condition) ? 'bg-green-100' : 'bg-gray-100'
            }`}>
              {getIcon(index)}
            </div>
            <span className={`text-left ${
              selectedConditions.includes(condition) ? 'text-green-700' : 'text-gray-900'
            }`}>
              {condition}
            </span>
          </motion.button>
        ))}
      </div>
    </div>
  );
};

export default MedicalHistory;
