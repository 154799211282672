import React, { Suspense } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Navbar from '../Navbar';
import Footer from '../Footer';
import LoadingSpinner from '../ui/LoadingSpinner';

interface SmoothLayoutProps {
  children: React.ReactNode;
}

const SmoothLayout: React.FC<SmoothLayoutProps> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col">
      <Navbar />
      <AnimatePresence mode="wait">
        <motion.main
          className="flex-grow"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <Suspense fallback={
            <div className="flex items-center justify-center min-h-[50vh]">
              <LoadingSpinner />
            </div>
          }>
            {children}
          </Suspense>
        </motion.main>
      </AnimatePresence>
      <Footer />
    </div>
  );
};

// Use React.memo to prevent unnecessary re-renders
export default React.memo(SmoothLayout);