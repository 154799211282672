import React from 'react';
import { motion } from 'framer-motion';
import { AlertCircle, Heart, Brain, Baby } from 'lucide-react';

interface ExclusionCriteriaProps {
  selectedCriteria: string[];
  onUpdate: (criteria: string[]) => void;
}

const exclusionCriteria = [
  {
    id: 'heart',
    text: 'Schwere Herz-Kreislauf-Erkrankungen',
    icon: Heart,
  },
  {
    id: 'psychosis',
    text: 'Aktuelle oder frühere Psychosen',
    icon: Brain,
  },
  {
    id: 'pregnancy',
    text: 'Schwangerschaft oder Stillzeit',
    icon: Baby,
  },
  {
    id: 'liver',
    text: 'Schwere Lebererkrankungen',
    icon: AlertCircle,
  },
  {
    id: 'addiction',
    text: 'Aktuelle Suchterkrankung',
    icon: Brain,
  },
  {
    id: 'allergy',
    text: 'Bekannte Cannabis-Allergie',
    icon: AlertCircle,
  },
];

const ExclusionCriteria: React.FC<ExclusionCriteriaProps> = ({
  selectedCriteria,
  onUpdate,
}) => {
  const toggleCriterion = (criterionId: string) => {
    if (selectedCriteria.includes(criterionId)) {
      onUpdate(selectedCriteria.filter((c) => c !== criterionId));
    } else {
      onUpdate([...selectedCriteria, criterionId]);
    }
  };

  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 200 }}
          className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <AlertCircle className="w-8 h-8 text-red-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">
          Ausschlusskriterien
        </h3>
        <p className="text-gray-600 mt-2">
          Bitte wählen Sie alle zutreffenden Kriterien aus
        </p>
      </div>

      <div className="space-y-4">
        {exclusionCriteria.map((criterion, index) => (
          <motion.button
            key={criterion.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            onClick={() => toggleCriterion(criterion.id)}
            className={`w-full p-4 rounded-xl border-2 transition-all duration-300 flex items-center gap-4 ${
              selectedCriteria.includes(criterion.id)
                ? 'border-red-500 bg-red-50'
                : 'border-gray-200 hover:border-red-500'
            }`}
          >
            <div
              className={`w-10 h-10 rounded-full flex items-center justify-center ${
                selectedCriteria.includes(criterion.id)
                  ? 'bg-red-100'
                  : 'bg-gray-100'
              }`}
            >
              <criterion.icon
                className={`w-6 h-6 ${
                  selectedCriteria.includes(criterion.id)
                    ? 'text-red-500'
                    : 'text-gray-400'
                }`}
              />
            </div>
            <span
              className={`text-left ${
                selectedCriteria.includes(criterion.id)
                  ? 'text-red-700'
                  : 'text-gray-900'
              }`}
            >
              {criterion.text}
            </span>
          </motion.button>
        ))}
      </div>

      {selectedCriteria.length > 0 && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="p-4 bg-red-50 rounded-xl"
        >
          <p className="text-red-700 text-sm">
            Leider können wir Ihnen aufgrund der ausgewählten Kriterien kein
            Cannabis verschreiben.
          </p>
        </motion.div>
      )}
    </div>
  );
};

export default ExclusionCriteria;
