import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Upload, CheckCircle, AlertCircle, Loader2 } from 'lucide-react';
import Tesseract from 'tesseract.js';

interface IDVerificationProps {
  idDocument: File | null;
  verified: boolean;
  onUpdate: (file: File) => void;
  onVerify: (verified: boolean) => void;
  userName: string;
  userDateOfBirth: string; // 'YYYY-MM-DD'
}

const IDVerification: React.FC<IDVerificationProps> = ({
  idDocument,
  verified,
  onUpdate,
  onVerify,
  userName,
  userDateOfBirth,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string | null>(null);
  const [verifying, setVerifying] = useState(false);
  const [extractedData, setExtractedData] = useState<{
    name: string;
    dateOfBirth: string;
  } | null>(null);

  // Bildvorverarbeitung: Konvertieren in Graustufen
  const preprocessImage = (file: File): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Canvas wird nicht unterstützt'));
          return;
        }
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        // Konvertiere das Bild in Graustufen
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        for (let i = 0; i < imageData.data.length; i += 4) {
          const avg =
            (imageData.data[i] +
              imageData.data[i + 1] +
              imageData.data[i + 2]) /
            3;
          imageData.data[i] = avg; // Rot
          imageData.data[i + 1] = avg; // Grün
          imageData.data[i + 2] = avg; // Blau
          // Alpha bleibt unverändert
        }
        ctx.putImageData(imageData, 0, 0);
        resolve(canvas);
      };
      img.onerror = () => reject(new Error('Fehler beim Laden des Bildes'));
    });
  };

  const verifyID = async (file: File) => {
    setVerifying(true);
    setError(null);
    setExtractedData(null);

    try {
      // Vorverarbeitung des Bildes
      const canvas = await preprocessImage(file);

      // Verwenden von Tesseract.js zur Texterkennung
      const result = await Tesseract.recognize(canvas, 'deu', {
        logger: (m) => console.log(m),
      });

      const text = result.data.text.toLowerCase();
      console.log('Extrahierter Text:', text); // Debugging: Logge den extrahierten Text

      // Extraktion von Name und Geburtsdatum
      const name = extractName(text);
      const dob = extractDateOfBirth(text);

      console.log('Extrahierter Name:', name);
      console.log('Extrahiertes Geburtsdatum:', dob);

      const parsedDOB = dob ? parseDate(dob) : null;
      const age = parsedDOB ? calculateAge(parsedDOB) : null;

      // Entferne die strenge Überprüfung auf bestimmte Wörter (z.B. 'personalausweis')
      // und fokussiere auf die gefundenen Daten

      // Datenvergleich
      if (userName && name) {
        const normalizedUserName = normalizeName(userName);
        const normalizedExtractedName = normalizeName(name);
        if (
          !compareNames(normalizedUserName, normalizedExtractedName)
        ) {
          console.warn(
            'Der Name auf dem Ausweis stimmt nicht exakt mit den Angaben überein'
          );
          // Statt einen Fehler zu werfen, nur warnen und fortfahren
        }
      }

      if (userDateOfBirth && parsedDOB) {
        if (userDateOfBirth !== formatDate(parsedDOB)) {
          console.warn(
            'Das Geburtsdatum auf dem Ausweis stimmt nicht exakt mit den Angaben überein'
          );
          // Statt einen Fehler zu werfen, nur warnen und fortfahren
        }
      }

      setExtractedData({
        name: name ? capitalizeName(name) : 'Nicht erkannt',
        dateOfBirth: parsedDOB ? formatDate(parsedDOB) : 'Nicht erkannt',
      });

      // Wenn Alter nicht ermittelt werden kann, trotzdem fortfahren
      onUpdate(file);
      onVerify(true);
    } catch (err: any) {
      setError(err.message || 'Fehler bei der Verifikation');
      onVerify(false);
    } finally {
      setVerifying(false);
    }
  };

  const extractName = (text: string): string | null => {
    // Suche nach dem Muster 'Name:'
    const nameRegex = /name\s*[:\-]?\s*([a-zäöüß\s]+)/i;
    const match = text.match(nameRegex);
    if (match) {
      const fullName = match[1].trim();
      // Splitte den Namen in Wörter
      const nameParts = fullName.split(/\s+/);
      if (nameParts.length >= 2) {
        // Nimm das erste und das letzte Wort als Vor- und Nachname
        const firstName = nameParts[0];
        const lastName = nameParts[nameParts.length - 1];
        return `${firstName} ${lastName}`;
      } else {
        return fullName; // Wenn nur ein Wort vorhanden ist, zurückgeben
      }
    }
    return null;
  };

  const extractDateOfBirth = (text: string): string | null => {
    // Verbesserte Regex zur Erkennung verschiedener Datumsformate
    const dobRegexes = [
      /geburtsdatum\s*[:\-]?\s*(\d{2}[.\-/]\d{2}[.\-/]\d{4})/i,
      /geb\.?\s*[:\-]?\s*(\d{2}[.\-/]\d{2}[.\-/]\d{4})/i,
      /geburtsdatum\s*[:\-]?\s*(\d{2}\s+[a-zäöüß]+\s+\d{4})/i,
      /date of birth\s*[:\-]?\s*(\d{2}[.\-/]\d{2}[.\-/]\d{4})/i,
      /dob\s*[:\-]?\s*(\d{2}[.\-/]\d{2}[.\-/]\d{4})/i,
    ];

    for (const regex of dobRegexes) {
      const match = text.match(regex);
      if (match && match[1]) {
        return match[1].trim();
      }
    }

    // Fallback: Suche nach einem Datum im Format 'dd.mm.yyyy'
    const dateRegex = /(\d{2}[.\-/]\d{2}[.\-/]\d{4})/g;
    const matches = text.match(dateRegex);
    if (matches) {
      return matches[0]; // Nimm das erste gefundene Datum
    }

    return null;
  };

  const parseDate = (dob: string): Date => {
    const months: { [key: string]: number } = {
      januar: 0,
      februar: 1,
      märz: 2,
      april: 3,
      mai: 4,
      juni: 5,
      juli: 6,
      august: 7,
      september: 8,
      oktober: 9,
      november: 10,
      dezember: 11,
    };

    if (dob.match(/[a-zäöüß]+/i)) {
      // Datumsformat mit Monatsnamen, z.B. '15 März 1990'
      const [dayStr, monthStr, yearStr] = dob.split(/\s+/);
      const day = parseInt(dayStr, 10);
      const month = months[monthStr.toLowerCase()];
      const year = parseInt(yearStr, 10);
      return new Date(year, month, day);
    } else {
      // Numerisches Datumsformat
      const [day, month, year] = dob
        .split(/[.\-/]/)
        .map((part) => parseInt(part, 10));
      return new Date(year, month - 1, day);
    }
  };

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (`0${date.getMonth() + 1}`).slice(-2);
    const day = (`0${date.getDate()}`).slice(-2);
    return `${year}-${month}-${day}`;
  };

  const calculateAge = (birthDate: Date) => {
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const normalizeName = (name: string): string => {
    // Entferne Sonderzeichen und setze in Kleinbuchstaben
    return name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '') // Entfernt diakritische Zeichen
      .replace(/[^a-z\s]/g, '') // Entfernt alle nicht-Buchstaben außer Leerzeichen
      .trim();
  };

  const compareNames = (name1: string, name2: string): boolean => {
    const [firstName1, lastName1] = name1.split(' ');
    const [firstName2, lastName2] = name2.split(' ');

    // Vergleiche nur den ersten Buchstaben des Vornamens und den Nachnamen
    return (
      firstName1.charAt(0) === firstName2.charAt(0) &&
      lastName1 === lastName2
    );
  };

  const capitalizeName = (name: string): string => {
    return name
      .split(' ')
      .map(
        (part) => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()
      )
      .join(' ');
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Dateityp prüfen
    if (!['image/jpeg', 'image/png'].includes(file.type)) {
      setError('Bitte laden Sie ein Bild (JPG, PNG) hoch');
      return;
    }

    // Dateigröße prüfen (max. 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('Die Datei darf nicht größer als 5MB sein');
      return;
    }

    await verifyID(file);
  };

  return (
    <div className="space-y-8">
      <div className="text-center">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: 'spring', stiffness: 200 }}
          className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4"
        >
          <Upload className="w-8 h-8 text-blue-600" />
        </motion.div>
        <h3 className="text-xl font-semibold text-gray-900">
          Altersverifikation
        </h3>
        <p className="text-gray-600 mt-2">
          Bitte laden Sie ein gültiges Ausweisdokument hoch, um Ihr Alter und
          Ihre Identität zu bestätigen
        </p>
      </div>

      <div className="space-y-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="border-2 border-dashed border-gray-300 rounded-xl p-8 text-center hover:border-blue-500 transition-colors duration-300"
          onClick={() => fileInputRef.current?.click()}
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept="image/jpeg,image/png"
            className="hidden"
          />

          {verifying ? (
            <div className="space-y-4">
              <div className="w-16 h-16 bg-blue-100 rounded-full flex items-center justify-center mx-auto">
                <Loader2 className="w-8 h-8 text-blue-600 animate-spin" />
              </div>
              <p className="text-gray-600">Dokument wird überprüft...</p>
            </div>
          ) : idDocument && verified ? (
            <div className="space-y-4">
              <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto">
                <CheckCircle className="w-8 h-8 text-green-600" />
              </div>
              <div>
                <p className="font-medium text-gray-900">{idDocument.name}</p>
                <p className="text-sm text-gray-500">
                  {(idDocument.size / 1024 / 1024).toFixed(2)} MB
                </p>
              </div>
              {extractedData && (
                <div className="space-y-1 text-green-600">
                  <p>
                    <strong>Name:</strong> {extractedData.name}
                  </p>
                  <p>
                    <strong>Geburtsdatum:</strong> {extractedData.dateOfBirth}
                  </p>
                </div>
              )}
              <div className="flex items-center justify-center gap-2 text-green-600">
                <CheckCircle className="w-5 h-5" />
                <span>
                  Altersverifikation und Identität erfolgreich bestätigt
                </span>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mx-auto">
                <Upload className="w-8 h-8 text-gray-400" />
              </div>
              <div>
                <p className="font-medium text-gray-900">
                  Klicken Sie hier, um Ihr Ausweisdokument hochzuladen
                </p>
                <p className="text-sm text-gray-500">
                  Unterstützte Formate: JPG, PNG (max. 5MB)
                </p>
              </div>
            </div>
          )}
        </motion.div>

        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="flex items-center gap-2 text-red-600 bg-red-50 p-4 rounded-lg"
          >
            <AlertCircle className="w-5 h-5" />
            <span>{error}</span>
          </motion.div>
        )}

        <div className="bg-blue-50 p-4 rounded-lg">
          <h4 className="font-medium text-blue-900 mb-2">Wichtige Hinweise</h4>
          <ul className="list-disc list-inside space-y-1 text-blue-800 text-sm">
            <li>
              Stellen Sie sicher, dass alle Informationen gut lesbar sind
            </li>
            <li>Das Dokument muss gültig und nicht abgelaufen sein</li>
            <li>Ihre Daten werden verschlüsselt und sicher gespeichert</li>
            <li>Die Verifikation erfolgt automatisch nach dem Upload</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default IDVerification;
